import api from "./index";

export default {
  async checkGuestEmail(email) {
    try {
      const response = await api.post("/checkout/users.json", {
        email
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async login(user) {
    try {
      const { data } = await api.post("/users/sign_in.json", {
        user
      });
      return {
        data,
        success: true
      };
    } catch (error) {
      return {
        data: error.response.data,
        success: false
      };
    }
  },
  async register(user) {
    try {
      const { data } = await api.post("/users.json", {
        user
      });
      return {
        data,
        success: true
      };
    } catch (error) {
      return {
        data: error.response.data,
        success: false
      };
    }
  },
  async update(params) {
    return api.put("/users", params);
  },
  async updatePreferences(params) {
    return api.patch("/users/preferences", params);
  },
  async subscribe(params) {
    return api.post("/subscribe", params);
  },
  async subscribeToSms(params) {
    return api.post("/subscribe_to_sms", params);
  },
  async deleteCard(cardId) {
    return api.delete(`/checkout/card/${cardId}`);
  }
};
